import { useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { PageHead, Column, Grid, block } from "src/layout/styled"
import { COLORS, LAYOUT } from "src/layout/constants"
import RichText from "src/components/RichText"
import Filter from "src/components/Filter"
import MissionBox from "src/components/Mission"

import PlusIcon from "src/svg/plus.svg"
import MinusIcon from "src/svg/minus.svg"
import SearchIcon from "src/svg/search.svg"
import DocumentIcon from "src/svg/document.svg"
import TargetIcon from "src/svg/target.svg"
import TrainingIcon from "src/svg/training.svg"
import GearsIcon from "src/svg/gears.svg"

export const ICONS: Record<Activity["icon"], React.FC> = {
  recherche: SearchIcon,
  document: DocumentIcon,
  cible: TargetIcon,
  formation: TrainingIcon,
  engrenages: GearsIcon,
}

interface Position {
  id: number // big ID (1, 2, 3, 4)
  min: number // minimum number of total items for this item to be big
}
// see https://v6v9w.csb.app/
const BIG_POSITIONS: Record<number, Position> = {
  5: { id: 1, min: 6 }, // 6th mission
  9: { id: 2, min: 12 }, // 10th mission
  20: { id: 3, min: 21 }, // 21st mission
  24: { id: 4, min: 27 }, // 25th mission
}

const ActivityIntro = styled.h2`
  ${block}
  font-size: 2em;
  font-weight: normal;
`
const ActivityDescription = styled.div`
  ${block}
  white-space: pre-line;
  p:first-of-type {
    margin-top: 0.5em;
  }
  @media (min-width: 600px) {
    font-size: 0.9em;
  }
`
const Toggler = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  border-top: 1px solid ${COLORS.green};
  padding: 12px 0 0;
  svg {
    width: 32px;
    height: 32px;
    circle {
      fill: ${COLORS.green};
    }
  }
`
const Missions = styled.div`
  margin: ${LAYOUT.margin * 2}px 0;
`
const IndexLink = styled.p`
  text-align: right;
  font-weight: bold;
`
const Mosaic = styled.div`
  margin: ${LAYOUT.margin * 2}px auto;
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    ". . ."
    ". b1 b1"
    ". b1 b1"
    ". . ."
    "b2 b2 ."
    "b2 b2 ."
    ". . ."
    ". . ."
    ". b3 b3"
    ". b3 b3"
    ". . ."
    "b4 b4 ."
    "b4 b4 .";

  @media (max-width: 919px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${LAYOUT.margin * 2}px -5px;
    > div {
      margin: 5px;
    }
  }
  @media (max-width: 599px) {
    justify-content: center;
    > div {
      margin: 0 0 ${LAYOUT.margin}px;
    }
  }
`

interface Data {
  activity: Activity
}

const ActivityPage: GatsbyPage<Data, Activity> = ({ data }) => {
  const { icon, title, intro, description, more, missions } = data.activity
  const Icon = ICONS[icon]

  const [open, setOpen] = useState(false)
  const [methodFilter, setMethodFilter] = useState<string>()

  const methods = new Set<string>()
  missions?.forEach((mission) => {
    if (mission.methods) {
      mission.methods.forEach((method) => {
        methods.add(method)
      })
    }
  })

  const filteredMissions = missions?.filter((mission) => {
    if (methodFilter && mission.methods && !mission.methods.includes(methodFilter)) {
      return false
    }
    return true
  })

  return (
    <Layout theme="green" title={title} description={intro.intro}>
      <PageHead>
        <Icon />
        <h1>{title[0].toLowerCase() + title.substr(1)}</h1>
      </PageHead>
      <Column>
        <Grid>
          <ActivityIntro>{intro.intro}</ActivityIntro>
          <ActivityDescription>
            <RichText node={description} />
            {more && (
              <>
                {open && <RichText node={more} />}
                <Toggler onClick={() => setOpen(!open)} title="Lire la suite">
                  {open ? <MinusIcon /> : <PlusIcon />}
                </Toggler>
              </>
            )}
          </ActivityDescription>
        </Grid>
        {missions && (
          <Missions>
            <Filter
              title="Filtres méthodologiques"
              values={Array.from(methods)}
              selected={methodFilter}
              onChange={setMethodFilter}
            />
            <IndexLink>
              <Link to="/missions/">Index des missions &gt;</Link>
            </IndexLink>
            <Mosaic>
              {filteredMissions.map((mission, index) => {
                const big = BIG_POSITIONS[index]
                return (
                  <MissionBox
                    light={(index + 1) % 2 === 0}
                    key={mission.id}
                    mission={mission}
                    bigId={big && filteredMissions.length >= big.min ? big.id : null}
                  />
                )
              })}
            </Mosaic>
          </Missions>
        )}
      </Column>
    </Layout>
  )
}

export default ActivityPage

export const query = graphql`
  query($slug: String!) {
    activity: contentfulActivity(slug: { eq: $slug }) {
      slug
      icon
      title
      intro {
        intro
      }
      description {
        json
      }
      more {
        json
      }
      missions {
        id: contentful_id
        title
        subtitle
        methods
        client
        clientShort
        start
        end
        photo {
          fixed(width: 280, height: 280, quality: 75) {
            ...GatsbyContentfulFixed_noBase64
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
