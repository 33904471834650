import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Image from "gatsby-image"

import InstitutionIcon from "src/svg/institution.svg"
import { clamp } from "src/layout/styled"
import { COLORS } from "src/layout/constants"

export const WIDTH = 280

const Container = styled.div<{ $light: boolean }>`
  width: ${WIDTH}px;
  height: ${WIDTH}px;
  flex: 0 0 ${WIDTH}px;
  position: relative;
  background-color: ${({ $light }) => ($light ? COLORS.lightPink : COLORS.pink)};
  @media (max-width: 599px) {
    font-size: 1.25em; /* cancel <main>'s font-size: 0.8em; I know I know */
  }
  @media (min-width: 920px) {
    &.big {
      width: ${10 + WIDTH * 2}px;
      height: ${10 + WIDTH * 2}px;
      h2 {
        font-size: 2.5em;
        height: 110px !important;
      }
      h3 {
        font-size: 1em;
      }
    }
    &.b1 {
      grid-area: b1;
    }
    &.b2 {
      grid-area: b2;
    }
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      filter: grayscale() brightness(75%);
      mix-blend-mode: multiply;
    }
  }
`
const Bottom = styled.div`
  font-size: 0.7em;
  font-weight: bold;
  display: flex;
  svg {
    flex: 0 0 45px;
    height: 32px;
    padding: 6px 0 7px;
    g {
      fill: ${COLORS.lightPink};
    }
  }
  span {
    border-left: 5px solid transparent;
    padding-left: 10px;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
  }
`
const hoverCss = css`
  h2 {
    ${clamp}
    height: 70px;
  }
  h2,
  h3,
  ${Bottom}, span {
    border-color: ${COLORS.lightPink};
  }
  h3,
  svg {
    visibility: visible;
  }
`
const Content = styled(Link)`
  position: relative;
  height: 100%;
  color: ${COLORS.lightPink};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  h2,
  h3,
  ${Bottom} {
    border: 5px solid transparent;
    margin: 0 0 -5px 0;
    text-transform: uppercase;
  }
  h3,
  svg {
    visibility: hidden;
  }
  &:hover {
    ${hoverCss}
  }
  @media (hover: none) {
    ${hoverCss}
  }
`
const Title = styled.h2`
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 4px 6px;
`
const Subtitle = styled.h3`
  flex: 1;
  font-size: 0.8em;
  padding: 6px;
`

interface Props {
  mission: Mission
  light: boolean
  bigId?: number
}

const MissionBox = ({ mission: { photo, title, subtitle, clientShort, client, fields }, light, bigId }: Props) => {
  return (
    <Container $light={light} className={bigId && "big"} style={bigId && { gridArea: "b" + bigId }}>
      <Image fixed={photo.fixed} />
      <Content to={`/missions/${fields.slug}/`}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Bottom>
          <InstitutionIcon />
          <span>{clientShort || client}</span>
        </Bottom>
      </Content>
    </Container>
  )
}

export default MissionBox
