import { Fragment } from "react"
import styled from "styled-components"

const Container = styled.p`
  line-height: 1.5em;
`
const FilterButton = styled.button<{ $selected: boolean }>`
  text-decoration: ${({ $selected }) => ($selected ? "underline" : "none")};
  padding: 0;
  color: inherit;
`

interface Props {
  title: string
  values: string[]
  selected: string
  onChange: (value: string) => void
}

const Filter = ({ title, values, selected, onChange }: Props) => (
  <Container>
    <strong>{title} : </strong>
    <FilterButton $selected={!selected} onClick={() => onChange(null)}>
      tous
    </FilterButton>
    {values.map((value: string) => (
      <Fragment key={value}>
        {" - "}
        <FilterButton $selected={value === selected} onClick={() => onChange(value)}>
          {value}
        </FilterButton>
      </Fragment>
    ))}
  </Container>
)

export default Filter
